/* ##remfixer: 1080 */

.barBlock {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  float: left;
  min-width: 1.611111rem;
  height: 1.092593rem;
  line-height: 1.092593rem;
  padding-left: 0.481481rem;
  padding-right: 0.138889rem;
  background-color: #fff;
  font-size: 0.333333rem;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.barBlock.first {
  padding-left: 0;
  padding-right: 0;
  min-width: 1.314815rem;
}

.barBlock.last {
  background-color: #F7F7F7;
}

.barBlock.last:before {
  position: absolute;
  display: block;
  content: '';
  width: 0.296296rem;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #fff;
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
}

.barBlock.last:after {
  position: absolute;
  display: block;
  content: '';
  width: 0;
  height: 0;
  top: -0.009259rem;
  right: 1px;
  z-index: 2;
  border-top: 0.564815rem solid transparent;
  border-left: 0.296296rem solid #F7F7F7;
  border-bottom: 0.564815rem solid transparent;
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
}

.barBlock:after {
  position: absolute;
  display: block;
  content: '';
  width: 0;
  height: 0;
  top: -0.009259rem;
  right: 1px;
  z-index: 2;
  border-top: 0.564815rem solid transparent;
  border-left: 0.296296rem solid #fff;
  border-bottom: 0.564815rem solid transparent;
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
}

.barBlock .icon {
  position: absolute;
  display: block;
  content: '';
  width: 0;
  height: 0;
  top: -0.009259rem;
  right: 0;
  z-index: 2;
  border-top: 0.564815rem solid transparent;
  border-left: 0.296296rem solid rgba(0, 0, 0, 0.1);
  border-bottom: 0.564815rem solid transparent;
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
}

.barBlock .link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}