/* ##remfixer: 1080 */

.gift {
  position: relative;
  width: 9.111111rem;
  height: 2.055556rem;
  margin-top: 0.222222rem;
  background: url('../../assets/images/gift_box.png') no-repeat;
  background-size: 100% 100%;
}

.gift_text {
  width: 5rem;
  height: 100%;
  float: left;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 2.111111rem;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gift_title {
  font-size: 0.444444rem;
  color: #FFFFFF;
  letter-spacing: 0;
  font-weight: 500;
  line-height: 0.527778rem;
  margin-bottom: 0.111111rem;
}

.gift_desc,
.gift_time {
  font-size: 0.333333rem;
  color: rgba(255, 255, 255, 0.4);
  letter-spacing: 0;
  line-height: 0.388889rem;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.gift_time {
  margin-bottom: 0;
}

.gift_plan {
  height: 0.259259rem;
  line-height: 0.259259rem;
  margin-bottom: 0.092593rem;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.gift_plan-box {
  float: left;
  width: 3.444444rem;
  height: 0.111111rem;
  margin-top: 0.074074rem;
  border-radius: 0.111111rem;
  background-color: #fff;
  overflow: hidden;
}

.gift_plan-v {
  display: block;
  width: 0;
  height: 100%;
  background-color: #FFCC23;
}

.gift_plan-txt {
  float: left;
  margin-left: 0.046296rem;
  font-size: 0.222222rem;
  color: #FFFFFF;
}

.gift .btn-look {
  position: absolute;
  width: 1.333333rem;
  height: 0.666667rem;
  top: 50%;
  right: 0.444444rem;
  border-radius: 0.666667rem;
  background-color: #fff;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.gift .btn-look span {
  display: block;
  width: 5.333333rem;
  height: 2.666667rem;
  line-height: 2.666667rem;
  font-size: 1.333333rem;
  -webkit-transform: scale(0.25, 0.25);
  transform: scale(0.25, 0.25);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  color: #F0764C;
  text-align: center;
}