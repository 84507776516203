/* ##remfixer: 1080 */

.app_desc {
  position: relative;
  font-size: 0.388889rem;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  line-height: 0.518519rem;
  overflow: hidden;
}

.app_desc div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.app_desc-more {
  display: block;
  width: 100%;
  font-size: 0.388889rem;
  color: #FC5B23;
  text-align: right;
}