/* ##remfixer: 1080 */

.AppInstallBtn {
  display: inline-block;
  position: relative;
  width: 1.333333rem;
  height: 0.666667rem;
  border-radius: 0.666667rem;
  background-color: #fc5b23;
  overflow: hidden;
}

.AppInstallBtn-btn {
  display: block;
  background-color: transparent;
  color: #fff;
  text-align: center;
  width: 8rem;
  height: 4rem;
  line-height: 4rem;
  font-size: 2rem;
  -webkit-transform: scale(0.16666667, 0.16666667);
  transform: scale(0.16666667, 0.16666667);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  font-weight: 500;
}

.AppInstallBtn-download {
  font-size: 0;
  visibility: hidden;
}