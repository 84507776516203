/* ##remfixer: 1080 */

.ImgBlockNormal {
  position: relative;
  float: left;
  width: 3.388889rem;
  height: 5.12963rem;
  margin-right: 0.111111rem;
  overflow: hidden;
}

.box {
  height: 100%;
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.img img {
  width: 100%;
  height: 100%;
}

.text {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0.222222rem;
  line-height: 0.5rem;
  font-size: 0.333333rem;
  color: #fff;
  letter-spacing: 0.017593rem;
  white-space: normal;
}