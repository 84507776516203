/* ##remfixer: 1080 */

.AppBlockKey {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  width: 7.5rem;
  height: 3.333333rem;
  padding: 0.333333rem;
  margin: 0.166667rem 0.111111rem 0.111111rem 0;
  background: url("../../assets/images/rectangle_bg1.png") no-repeat;
  background-size: 100% 100%;
}

.box {
  position: relative;
  width: 100%;
  height: 1.666667rem;
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.img {
  float: left;
  width: 1.666667rem;
  height: 1.666667rem;
  border-radius: 0.333333rem;
  overflow: hidden;
}

.img img {
  width: 100%;
  height: 100%;
}

.text {
  width: 3.333333rem;
  height: 100%;
  float: left;
  margin: 0.37037rem 0 0 0.333333rem;
  text-align: left;
  overflow: hidden;
}

.title {
  line-height: 0.444444rem;
  margin-bottom: 0.055556rem;
  font-size: 0.388889rem;
  color: #000;
  letter-spacing: 0;
}

.grade {
  width: 100%;
  font-size: 0.333333rem;
  line-height: 0.388889rem;
  color: #ffaa00;
  margin-bottom: 0.37037rem;
}

.grade-unit {
  font-size: 0.222222rem;
  font-weight: bold;
}

.appInstall {
  position: absolute;
  width: 1.333333rem;
  height: 0.666667rem;
  top: 0.833333rem;
  right: 0;
  margin-top: -0.333333rem;
}

.key {
  width: 100%;
  margin-top: 0.425926rem;
  text-align: center;
}

.key-txt {
  max-width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  padding: 0 0.574074rem;
  font-size: 0.388889rem;
  color: #000;
  background: url("../../assets/images/Combined Shape.png") no-repeat left center;
  background-size: 0.388889rem 0.296296rem;
}

.key-txt span {
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.key-icon {
  position: absolute;
  width: 0.388889rem;
  height: 100%;
  top: 0;
  right: 0;
  background: url("../../assets/images/Combined Shape.png") no-repeat left center;
  background-size: 0.388889rem 0.296296rem;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}