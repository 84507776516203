/* ##remfixer: 1080 */

.comment {
  position: relative;
  width: 100%;
  margin-top: 0.462963rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.comment .app_desc {
  line-height: 0.666667rem;
  font-size: 0.444444rem;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 0.462963rem;
}

.box {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding-left: 1.277778rem;
}

.icon {
  position: absolute;
  width: 0.833333rem;
  height: 0.833333rem;
  top: 0;
  left: 0;
  border-radius: 0.833333rem;
  overflow: hidden;
}

.icon img {
  width: 100%;
  height: 100%;
}

.desc {
  line-height: 0.666667rem;
  font-size: 0.444444rem;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 0.148148rem;
}

.time {
  font-size: 0.333333rem;
  color: #000000;
  line-height: 0.388889rem;
  margin-bottom: 0.111111rem;
  opacity: 0.4;
}

.name {
  font-size: 0.388889rem;
  color: #000000;
  line-height: 0.444444rem;
  margin-bottom: 0.148148rem;
}

.likes {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.333333rem;
  color: rgba(0, 0, 0, 0.4);
  line-height: 0.444444rem;
}

.likes span {
  padding-right: 0.537037rem;
  background-size: 0.444444rem auto;
  background-position: right center;
  background-repeat: no-repeat;
  margin-left: 0.388889rem;
}

.likes .trample {
  background-image: url("../../assets/images/like_dont.png");
}

.likes .like {
  background-image: url("../../assets/images/like.png");
}