/* ##remfixer: 1080 */

.coupon {
  position: relative;
  width: 9.111111rem;
  height: 2.777778rem;
  margin-bottom: 0.222222rem;
  background: url("~assets/images/coupon.png") no-repeat;
  background-size: 100% 100%;
}

.coupon.type2 {
  background-image: url("~assets/images/coupon2.png");
}

.coupon_money {
  float: left;
  text-align: center;
  width: 2.87037rem;
  height: 100%;
  overflow: hidden;
}

.coupon_num {
  font-size: 1.111111rem;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 2.777778rem;
  font-weight: bold;
}

.coupon_unit {
  font-size: 0.444444rem;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 2.777778rem;
}

.coupon_text {
  width: 4.814815rem;
  height: 100%;
  float: left;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0.425926rem;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.coupon_title {
  font-size: 0.444444rem;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 500;
  line-height: 0.527778rem;
  margin-bottom: 0.083333rem;
}

.coupon_desc,
.coupon_time {
  font-size: 0.333333rem;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 0;
  line-height: 0.388889rem;
  margin-bottom: 0.138889rem;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.coupon_time {
  margin-bottom: 0;
}