/* ##remfixer: 1080 */

.AppBlockCoupon {
  position: relative;
  width: 100%;
}

.apps {
  position: relative;
  width: 100%;
  padding: 0.222222rem 0;
  margin-bottom: 0.111111rem;
  overflow: hidden;
}

.box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-right: 1.666667rem;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.link {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.img {
  float: left;
  width: 1.777778rem;
  height: 1.777778rem;
  margin-right: 0.444444rem;
  border-radius: 0.111111rem;
  overflow: hidden;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.img img {
  width: 100%;
  height: 100%;
}

.text {
  float: left;
  width: 5rem;
  margin: 0.148148rem 0 0 0;
  text-align: left;
}

.app-title {
  line-height: 0.527778rem;
  margin-bottom: 0.083333rem;
  font-size: 0.444444rem;
  color: #000;
  letter-spacing: 0;
}

.app-num,
.app-desc {
  line-height: 0.388889rem;
  margin-bottom: 0.083333rem;
  font-size: 0.333333rem;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
}

.app-size {
  margin-right: 0.092593rem;
}

.app-desc {
  margin-bottom: 0.055556rem;
}

.app-sale {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  line-height: 0.333333rem;
  display: inline-block;
  padding: 0.111111rem 0.222222rem;
  font-size: 0.277778rem;
  color: #FD7D2C;
  letter-spacing: 0;
  background-color: #FFF3E5;
  white-space: nowrap;
}

.appInstall {
  position: absolute;
  width: 1.333333rem;
  height: 0.666667rem;
  top: 50%;
  right: 0;
  margin-top: -0.333333rem;
}

.coupons {
  width: 100%;
}