/* ##remfixer: 1080 */

.CommentBlock {
  position: relative;
  width: 100%;
}

.comment_box {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 0 0.444444rem 0.333333rem;
  margin-bottom: 0.333333rem;
  background-color: #fff;
}

.comment_box:after {
  display: block;
  content: "";
  clear: both;
}

.app_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 0.666667rem;
  padding: 0.333333rem 0;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0.277778rem;
}

.app_icon {
  display: block;
  width: 0.666667rem;
  height: 0.666667rem;
}

.app_name {
  margin-left: 0.111111rem;
  font-size: 0.388889rem;
  color: #000000;
  letter-spacing: 0;
}

.app_grade {
  display: inline-block;
  height: 0.444444rem;
  line-height: 0.444444rem;
  padding: 0 0.092593rem;
  margin-left: 0.083333rem;
  background-color: #ffb700;
  font-size: 0.333333rem;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 500;
}

.app_grade i {
  font-weight: bold;
  font-size: 0.222222rem;
}

.app_desc {
  width: 100%;
  line-height: 0.666667rem;
  font-size: 0.444444rem;
  color: rgba(0, 0, 0, 0.8);
}

.app_info {
  width: 100%;
  margin-top: 0.203704rem;
  overflow: hidden;
}

.comment_user {
  width: 80%;
  float: left;
}

.comment_user-icon {
  float: left;
  width: 0.444444rem;
  height: 0.444444rem;
}

.comment_user-name {
  float: left;
  line-height: 0.444444rem;
  margin-left: 0.166667rem;
  font-size: 0.333333rem;
  color: rgba(0, 0, 0, 0.4);
}

.comment_like {
  width: 20%;
  float: right;
  text-align: right;
  font-size: 0;
}

.comment_like-val {
  display: inline-block;
  line-height: 0.444444rem;
  font-size: 0.333333rem;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  vertical-align: middle;
}

.comment_like-icon {
  width: 0.444444rem;
  height: 0.444444rem;
  display: inline-block;
  margin-left: 0.083333rem;
  background: url("../../assets/images/like.png") no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
}