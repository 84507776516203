/* ##remfixer: 1080 */

.BlockBox {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 0.333333rem;
}

.block_box {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.box,
.block_list {
  width: 100%;
  font-size: 0;
}

.roll_box {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.roll_box::-webkit-scrollbar {
  display: none;
}

.block_list {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: left;
  min-width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 0.444444rem;
}

.block_li {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  min-width: 10px;
  width: auto;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.block_li.l-first > div {
  margin-left: 0 !important;
}

.block_li.l-last > div {
  float: right;
  margin-right: 0 !important;
}

.block_li.last > div {
  margin-right: 0 !important;
}

.block_li.last._y > div {
  margin-bottom: 0 !important;
  border-bottom: none;
}

.block_li.last > div .black_gap {
  display: none;
}

.black_gap {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 0;
  padding-top: 0.425926rem;
  margin-bottom: 0.425926rem;
}