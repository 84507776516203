/* ##remfixer: 1080 */

.AppBlockCardSmall {
  position: relative;
  width: 5.555556rem;
  height: 4.425926rem;
  margin-top: 0.166667rem;
  margin-right: 0.111111rem;
  padding-bottom: 0.444444rem;
}

.AppBlockCardSmall:after {
  display: block;
  content: '';
  clear: both;
}

.box {
  position: relative;
  width: 100%;
  -webkit-box-shadow: 0 0.037037rem 0.037037rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.img {
  position: relative;
  width: 100%;
  height: 2.481481rem;
  overflow: hidden;
}

.img img {
  width: 100%;
  height: 100%;
  border-radius: 0.037037rem 0.037037rem 0 0;
}

.app {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 0 0.333333rem;
  left: 0;
  bottom: 0.222222rem;
  font-size: 0;
  line-height: 0.444444rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.app-name {
  display: inline-block;
  max-width: 100%;
  font-size: 0.388889rem;
  color: #fff;
  letter-spacing: 0;
  margin-right: 0.111111rem;
  vertical-align: middle;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
}

.app-grade {
  line-height: 0.388889rem;
  padding: 0.027778rem 0.083333rem;
  font-size: 0.333333rem;
  color: #FFFFFF;
  letter-spacing: 0;
  background: #FFB700;
  font-weight: 500;
  vertical-align: middle;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.app-grade .unit {
  font-size: 0.222222rem;
  font-weight: bold;
}

.text {
  padding: 0.277778rem 0.333333rem;
}

.desc {
  height: 0.888889rem;
  line-height: 0.444444rem;
  margin-bottom: 0.166667rem;
  font-size: 0.333333rem;
  color: #000;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.author {
  line-height: 0.333333rem;
  font-size: 0.277778rem;
  color: rgba(0, 0, 0, 0.6);
}