/* ##remfixer: 1080 */

.NavBar {
  position: relative;
  width: 100%;
  background-color: #fff;
  margin-bottom: 0.222222rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.NavBar .list {
  width: 100%;
}

.NavBar .item {
  float: left;
}