/* ##remfixer: 1080 */

.block_grade {
  height: 0.388889rem;
  line-height: 0.388889rem;
  margin-bottom: 0.138889rem;
  font-size: 0;
}

.block_grade-value {
  margin-left: 0.194444rem;
  font-size: 0.333333rem;
  color: #ff9700;
  letter-spacing: 0;
}

.block_stars,
.block_star {
  display: inline-block;
  width: 1.62037rem;
  height: 0.277778rem;
  background-size: 1.62037rem 100%;
  background-repeat: no-repeat;
  background-image: url(~assets/images/star-off.png);
}

.block_star {
  background-image: url(~assets/images/star-on.png);
}