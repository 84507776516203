/* ##remfixer: 1080 */

.block {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.info_rn_c1_big_img_expand > .block_box {
  padding: 0;
}

.gift_rown_col4_f6 .AppBlockSmall,
.coupon_row1_col1_f6 .AppBlockCoupon {
  margin-right: 0.814815rem;
}

.channel_ad_f6 .ImgBlockNormal {
  width: 100%;
  height: 3.888889rem;
}

.info_rn_c1_big_img_expand {
  padding-bottom: 0;
}

.info_rn_c1_big_img_expand .box {
  padding: 0;
}

.info_rn_c1_big_img_expand .AppBlockCard {
  padding-bottom: 0.333333rem;
}