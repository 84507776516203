/* ##remfixer: 1080 */

.AppBlockCard {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin-top: 0.111111rem;
  margin-bottom: 0.333333rem;
}

.AppBlockCard:after {
  display: block;
  content: "";
  clear: both;
}

.box {
  position: relative;
  width: 100%;
  -webkit-box-shadow: 0 0.037037rem 0.037037rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.img {
  width: 100%;
  height: 3.888889rem;
  overflow: hidden;
}

.img img {
  width: 100%;
  height: 100%;
}

.app {
  padding: 0.277778rem 0.444444rem 0;
}

.app-title {
  line-height: 0.527778rem;
  margin-bottom: 0.12963rem;
  font-size: 0.444444rem;
  color: #000;
  letter-spacing: 0;
}

.app-desc {
  line-height: 0.481481rem;
  margin-bottom: 0.083333rem;
  font-size: 0.333333rem;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.app-info {
  width: 100%;
  height: 0.555556rem;
  line-height: 0.555556rem;
  margin-top: 0.231481rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.277778rem 0;
  font-size: 0;
  text-align: left;
  vertical-align: middle;
}

.app-icon {
  display: inline-block;
  width: 0.555556rem;
  height: 0.555556rem;
  margin-right: 0.277778rem;
  border-radius: 0.055556rem;
  overflow: hidden;
  vertical-align: middle;
}

.app-icon img {
  width: 100%;
  height: 100%;
}

.app-name {
  font-size: 0.388889rem;
  color: #000000;
  letter-spacing: 0;
  margin-right: 0.138889rem;
  vertical-align: middle;
}

.app-grade {
  line-height: 0.388889rem;
  padding: 0.027778rem 0.083333rem;
  font-size: 0.333333rem;
  color: #ffffff;
  letter-spacing: 0;
  background: #ffb700;
  font-weight: 500;
  vertical-align: middle;
}

.app-grade .unit {
  font-size: 0.222222rem;
  font-weight: bold;
}