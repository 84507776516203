/* ##remfixer: 1080 */

.NewsList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  padding: 0.25rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.image {
  float: right;
  width: 3rem;
  height: 2rem;
  margin-left: 0.444444rem;
  border-radius: 0.018519rem;
  overflow: hidden;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.image img {
  width: 100%;
  height: 100%;
}

.text {
  width: 100%;
  float: left;
  margin-top: 0.083333rem;
  text-align: left;
}

.title {
  height: 1.074074rem;
  max-height: 1.074074rem;
  line-height: 0.527778rem;
  margin-bottom: 0.25rem;
  font-size: 0.444444rem;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.info {
  font-size: 0.333333rem;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
  line-height: 0.388889rem;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}