/* ##remfixer: 1080 */

.loadmore-list-container {
  position: relative;
}

.loading-bottom {
  margin-top: 0.185185rem;
}

.loading-spinner {
  line-height: 0.388889rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.138889rem 0;
  color: #000;
  font-size: 12px;
}

.loading-spinner img {
  width: 0.333333rem;
  height: 0.333333rem;
  margin-right: 0.277778rem;
}

.no-more {
  line-height: 0.388889rem;
  padding: 0.138889rem 0;
}

.no-more p {
  text-align: center;
  line-height: 0.333333rem;
  color: #000;
  font-size: 12px;
}

.sizeShow-enter-active,
.sizeShow-leave-active {
  -webkit-transition: height 2s;
  transition: height 2s;
}

.sizeShow-enter,
.sizeShow-leave-to {
  height: 0;
}