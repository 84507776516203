/* ##remfixer: 1080 */

.swiper {
  padding: 0 0 0 0.222222rem;
  height: 3.12963rem;
  margin-top: 0.222222rem;
  margin-bottom: 0.333333rem;
}

.swiper-box {
  width: 100%;
  height: 100%;
}

.swiper-container {
  position: relative;
  width: 7.333333rem;
  height: 3.12963rem;
  overflow: visible;
}

.swiper-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.swiper-slide {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.swiper img {
  width: 100%;
  height: 100%;
}

.swiper a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.swiper-mask {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 100%;
  margin-left: 6px;
}