/* ##remfixer: 1080 */

.AppBlockSmall {
  position: relative;
  width: 1.666667rem;
  margin-right: 0.611111rem;
  margin-top: 0.111111rem;
}

.AppBlockSmall:after {
  display: block;
  content: "";
  clear: both;
}

.link {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
}

.link.dl {
  bottom: 0.833333rem;
}

.img {
  float: left;
  width: 1.666667rem;
  height: 1.666667rem;
  margin-bottom: 0.194444rem;
  border-radius: 0.111111rem;
  overflow: hidden;
}

.img img {
  width: 100%;
  height: 100%;
}

.text {
  float: left;
  width: 100%;
  margin: 0;
  margin-bottom: 0.138889rem;
  text-align: center;
}

.app-title {
  font-size: 0.388889rem;
  color: #000;
  letter-spacing: 0;
  width: 120%;
  line-height: 0.444444rem;
  margin-left: -10%;
  margin-bottom: 0.111111rem;
}

.app-num,
.app-desc {
  line-height: 0.388889rem;
  margin-bottom: 0.083333rem;
  font-size: 0.333333rem;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
}

.app-num {
  width: 100%;
  line-height: 0.333333rem;
}

.appInstall {
  position: static;
  width: 100%;
  text-align: center;
  margin: 0;
  overflow: hidden;
}

.appInstall:after {
  content: " ";
  display: block;
  font-size: 0;
  width: 0;
  height: 0;
  clear: both;
}