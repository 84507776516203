/* ##remfixer: 1080 */

.AppBlockGrade {
  position: relative;
  width: 100%;
  max-height: 2.5rem;
  padding: 0.222222rem 0;
}

.block_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding-right: 1.666667rem;
}

.block_link {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.block_img {
  float: left;
  width: 1.777778rem;
  height: 1.777778rem;
  border-radius: 0.111111rem;
  overflow: hidden;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.block_img img {
  width: 100%;
  height: 100%;
}

.block_text {
  width: 5.111111rem;
  float: left;
  margin: 0.148148rem 0 0 0.444444rem;
  text-align: left;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.block_app-title {
  width: 100%;
  line-height: 0.527778rem;
  margin-bottom: 0.083333rem;
  font-size: 0.444444rem;
  color: #000;
  letter-spacing: 0;
}

.block_app-num,
.block_app-desc {
  width: 100%;
  line-height: 0.388889rem;
  margin-bottom: 0.083333rem;
  font-size: 0.333333rem;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
}

.block_app-size {
  margin-right: 0.185185rem;
}

.block_app-desc {
  margin-bottom: 0.055556rem;
}

.block_app-sale {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  line-height: 0.333333rem;
  display: inline-block;
  padding: 0.111111rem 0.222222rem;
  font-size: 0.277778rem;
  color: #fd7d2c;
  letter-spacing: 0;
  background-color: #fff3e5;
  white-space: nowrap;
}

.block_installBtn {
  position: absolute;
  width: 1.333333rem;
  height: 0.666667rem;
  top: 50%;
  right: 0;
  margin-top: -0.333333rem;
}